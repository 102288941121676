<template>
  <main class="notifications-container">
    <Dots v-if="!ready" />
    <div v-else class="notifications-content">
      <section class="header">
        <div class="left">
          {{ $t('serviceProvider.notification.notifications') }} ({{
            $store.getters.unreadNotifications
          }})
        </div>
        <div class="right">
          <ButtonElement @click="handleMarkAllAsRead" link>
            Alle als gelesen markieren
          </ButtonElement>
        </div>
      </section>
      <section class="notifications-list" ref="pageRef" v-on="scrollListeners">
        <div
          v-for="item of $store.getters.notifications"
          :key="item.id"
          class="notification-item"
        >
          <NotificationCard :unread="!item.readAt">
            <template #icon>
              <NotificationIcon :type="item.type" />
            </template>
            <template #info>
              <NotificationMessageFormat :data="item.data.title" type="title" />
            </template>
            <template #content>
              <NotificationMessageFormat
                :data="item.data.message"
                type="message"
              />
            </template>
            <template #time>
              {{ timeDuration(new Date(item.updatedAt)) }}
            </template>
          </NotificationCard>
        </div>
      </section>
      <div v-if="pageLoad" class="loader">
        <Dots />
      </div>
    </div>
  </main>
</template>

<script>
import { onMounted, onUnmounted, ref, watchEffect } from '@vue/composition-api';
import NotificationCard from '../components/Notifications/NotificationCard.vue';
import ButtonElement from '../components/FormControl/ButtonElement.vue';
import store from '../store';
import { timeDuration } from '../utils/dateTimeHelpers';
import NotificationIcon from '../components/Notifications/NotificationIcon.vue';
import useInfiniteScroll from '../composables/useInfiniteScroll';
import NotificationMessageFormat from '../components/Notifications/NotificationMessageFormat.vue';
import Dots from '../components/Loaders/Dots.vue';

export default {
  setup(props) {
    const ready = ref(false);
    const pageLoad = ref(false);
    const disableScrollHandler = ref(false);

    const [pageRef, scrollListeners] = useInfiniteScroll(
      async (currentPage, shouldFetchNextPage) => {
        console.log('run callback');
        if (shouldFetchNextPage) {
          pageLoad.value = true;
          await store.dispatch('fetchNotifications', { page: currentPage + 1 });
          pageLoad.value = false;
        }
      },
      disableScrollHandler
    );

    const handleMarkAllAsRead = async () => {
      disableScrollHandler.value = true;
      await store.dispatch('markAllNotificationsRead');

      process.nextTick(() => {
        disableScrollHandler.value = false;
      });
    };

    onMounted(async () => {
      console.log('onMounted runs');
      await store.dispatch('fetchNotifications');

      ready.value = true;
    });

    onUnmounted(() => {
      console.log('onUnmounted runs');
    });

    return {
      timeDuration,
      pageRef,
      scrollListeners,
      ready,
      pageLoad,
      handleMarkAllAsRead,
    };
  },
  components: {
    Dots,
    NotificationMessageFormat,
    NotificationIcon,
    ButtonElement,
    NotificationCard,
  },
  props: {
    // propName: {
    //   type: Number,
    //   default:
    // },
  },
};
</script>

<style lang="scss" scoped>
.notifications-container {
  padding: 1rem 1.5rem 0;
  background-color: $hty-grey-background;
  height: calc(100vh - 110px);
}
.notifications-content {
  height: 100%;
  position: relative;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.2;
  color: $hty-orange;
}
.right {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-orange;
}
.notifications-list {
  margin-top: 1rem;
  overflow-y: auto;
  height: calc(100% - 2.8rem);

  > :not(:first-child) {
    margin-top: 1rem;
  }
}
.loader {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $hty-grey-background;
}
</style>
