import { ref, computed } from '@vue/composition-api';
import { debounce } from 'lodash';
import store from '../store';

export default function useInfiniteScroll(
  handlerToInvoke,
  disableScrollHandler // this is ref
) {
  const element = ref(null);
  const currentPage = computed(
    () => store.getters.notificationsPagination.currentPage
  );

  const shouldFetchNextPage = computed(
    () => currentPage.value < store.getters.notificationsPagination.pages
  );

  const scroll = async event => {
    console.log('now runs main scroll handler');
    const maxScrollTop =
      // reduce more with magic number to ensure it will be greater
      // than currentScrollTop
      // prolly needs more testing when we have 10 pages or more of
      // notifications
      element.value.scrollHeight - element.value.offsetHeight - 4;
    const currentScrollTop = event.target.scrollTop;

    const elementBottom = currentScrollTop >= maxScrollTop;

    if (elementBottom) {
      await handlerToInvoke(currentPage.value, shouldFetchNextPage.value);
    }
  };

  const emptyScroll = event => {
    console.log('now runs empty scroll handler');
    event.target.scrollTop = 0;
  };

  const listeners = computed(() => {
    return {
      scroll: !disableScrollHandler.value ? debounce(scroll, 100) : emptyScroll,
    };
  });

  return [element, listeners];
}
