<template>
  <div class="dots" :class="{ small }">
    <div
      class="dots__child dots__child--bounce1"
      :class="{ red, blue, green }"
    ></div>
    <div
      class="dots__child dots__child--bounce2"
      :class="{ red, blue, green }"
    ></div>
    <div
      class="dots__child dots__child--bounce3"
      :class="{ red, blue, green }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    red: {
      type: Boolean,
      default: false,
    },
    blue: {
      type: Boolean,
      default: false,
    },
    green: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
$spinkit-spinner-margin: 0 auto !default;
$spinkit-size: 1em !default;

.dots {
  $animationDuration: 1.2s;
  $delayRange: 0.32s;
  margin: $spinkit-spinner-margin;
  min-width: $spinkit-size * 3;
  text-align: center;

  &.small {
    font-size: 0.7rem;
  }

  .dots__child {
    width: $spinkit-size / 2;
    height: $spinkit-size / 2;
    background-color: $hty-dark-grey;
    margin: 0 0.1em;

    border-radius: 100%;
    display: inline-block;
    animation: dots__child $animationDuration ease-in-out 0s infinite both;

    &.red {
      background-color: $hty-red;
    }
    // &.blue {
    //   background-color: $hty-blue;
    // }
    // &.green {
    //   background-color: $hty-green;
    // }
  }

  .dots__child--bounce1 {
    animation-delay: -$delayRange;
  }
  .dots__child--bounce2 {
    animation-delay: -$delayRange / 2;
  }
}

@keyframes dots__child {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>
